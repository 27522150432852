import React from "react";
import "./ProductItem.scss";
import { connect } from "react-redux";
import { addToCart } from "../../store/actions/cart";
import { Link } from "react-router-dom";
import AddToFav from "../AddToFav/AddToFav";
import AnimatedImage from "../AnimatedImage/AnimatedImage";
import { getDefaultImageUrl } from "../../utils/misc";
import { registerProductSlug } from "../../utils/slugs";

function ProductItem({
  title,
  category,
  price,
  currency,
  images,
  id,
}) {
  const defaultImage = getDefaultImageUrl(images);
  const productSlug = registerProductSlug(id, title);

  return (
    <div className="col-xl-3 col-lg-4 col-6 d-flex flex-column justify-content-between align-items-center mb-3">
      <div className="product-item">
        <AddToFav productId={id} />
        <Link
          to={`/product/${productSlug}`}
          className="d-flex flex-column justify-content-center align-items-center "
        >
          <div className="d-flex flex-column justify-content-center align-items-center image-container">
            <AnimatedImage
              imageUrl={defaultImage?.url}
              alt="Product Photo"
              className=""
            />
          </div>
        </Link>
        <div className="p-2 d-flex flex-column flex-fill">
          <h2>{category.name}</h2>
          <Link to={`/product/${productSlug}`} className="mb-3">
            <h3 className="mb-1">{title}</h3>
          </Link>
          <div className="d-flex align-items-center mt-auto">
            <h2 className="text-center mb-0">{price + ' ' +  currency}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (product) => dispatch(addToCart(product)),
  };
}

export default connect(null, mapDispatchToProps)(ProductItem);