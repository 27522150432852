import { createSelector } from "reselect";
import { selectUserFavorites } from "./user";
import { getCategoryIdFromSlug } from "../../utils/slugs";

const selectProducts = (state) => state.products;

// Modified to handle URL path parameters
export const selectURLSearchParams = (state, ownProps) => {
  const params = new URLSearchParams(ownProps.history.location.search);
  
  // Check if we have a category in the URL path
  if (ownProps.match.params.categorySlug) {
    if (ownProps.match.params.categorySlug === "favorites") {
      params.set("category", "favorites");
    } else {
      const categoryId = getCategoryIdFromSlug(ownProps.match.params.categorySlug);
      if (categoryId) {
        params.set("category", categoryId);
      }
    }
  }
  
  
  return params;
};

const selectFilters = createSelector([selectURLSearchParams], (params) => {
  let filterBy = {};
  for (let pair of params.entries()) {
    if (pair[0] !== "sort") {
      filterBy = {
        ...filterBy,
        [pair[0]]: pair[1].split(","),
      };
    }
  }
  return filterBy;
});

const selectSort = createSelector([selectURLSearchParams], (params) => {
  let sortBy = "default";
  for (let pair of params.entries()) {
    if (pair[0] === "sort") {
      sortBy = pair[1];
    }
  }
  return sortBy;
});

export const selectProductsData = createSelector(
  [selectProducts],
  (products) => products.data
);

export const selectProductsLoading = createSelector(
  [selectProducts],
  (products) => products.loading
);

export const selectFilteredProducts = createSelector(
  [selectProductsData, selectFilters, selectUserFavorites],
  (products, filters, favorites) => {
    let filteredProducts = products.slice();
    if (!filters) return filteredProducts;

    // Filter by category (including 'favorites')
    if (filters.category) {
      if (filters.category.includes("favorites") && favorites) {
        filteredProducts = filteredProducts.filter((product) =>
          favorites.some((element) => element === product.id)
        );
      } else {
        filteredProducts = filteredProducts.filter((product) =>
          filters.category.includes(product.category.id)
        );
      }
    }

    // Filter by price range and other filters (Type, Color, Size)
    for (let filterKey of Object.keys(filters)) {
      if (filterKey !== "category") {
        if (filterKey === "minPriceInput") {
          filteredProducts = filteredProducts.filter(
            (product) => Number(product.price) >= Number(filters[filterKey])
          );
        } else if (filterKey === "maxPriceInput") {
          filteredProducts = filteredProducts.filter(
            (product) => Number(product.price) <= Number(filters[filterKey])
          );
        } else if (["colors", "sizes", "types"].includes(filterKey)) {
          if (filters[filterKey]?.[0] === "Unisex") {
            const requiredTypes = ["Male", "Female"];
            filteredProducts = filteredProducts.filter((product) =>
              requiredTypes.every((type) => product.types?.includes(type))
            );
          } else {
            filteredProducts = filteredProducts.filter((product) =>
              product[filterKey].some((value) =>
                filters[filterKey].includes(value)
              )
            );
          }
        } else {
          filteredProducts = filteredProducts.filter(
            (product) => product[filterKey] === filters[filterKey]?.[0]
          );
        }
      }
    }

    return filteredProducts;
  }
);

export const selectSortedProducts = createSelector(
  [selectFilteredProducts, selectSort],
  (filteredProducts, sort) => {
    const arrCpy = filteredProducts.slice();
    if (sort === "ascending") {
      arrCpy.sort((a, b) => Number(a.price) - Number(b.price));
    } else if (sort === "descending") {
      arrCpy.sort((a, b) => Number(b.price) - Number(a.price));
    }
    return arrCpy;
  }
);

export const selectFilterOptions = createSelector(
  [selectProductsData],
  (products) => {
    const brands = [];
    const materials = [];
    const prices = [];
    const colors = [];
    const sizes = [];
    const types = [];

    products.forEach((item) => {
      brands.push(item["brand"]);
      materials.push(item["material"]);
      prices.push(item["price"]);
      colors.push(item["color"]);
      sizes.push(item["size"]);
      types.push(item["type"]);
    });

    return {
      brands: [...new Set(brands)],
      materials: [...new Set(materials)],
      prices: [...new Set(prices)],
      colors: [...new Set(colors)],
      sizes: [...new Set(sizes)],
      types: [...new Set(types)],
    };
  }
);