import React, { Component } from 'react';
import Layout from '../../components/Layout/Layout';
import { connect } from 'react-redux';

import './Product.scss';
import WithSpinner from '../../components/HOCs/WithSpinner';

import { addToCart } from '../../store/actions/cart';
import { getUserData } from '../../store/actions/user';
import { selectUserEmail, selectUserData } from '../../store/selectors/user';
import { selectIsFavorite } from '../../store/selectors/user';
import ProductInfo from '../../components/ProductInfo/ProductInfo';

import { fetchProductData, addToFavorites, deleteFromFavorites } from '../../apis/endpoints';
import { getProductIdFromSlug, registerProductSlug } from '../../utils/slugs';

const ProductInfoWithSpinner = WithSpinner(ProductInfo);

class Product extends Component {
	constructor() {
		super();
		this.state = {
			product: '',
			loading: false,
			errors: ''
		};
		this.addToFavorites = this.addToFavorites.bind(this);
	}
	
	componentDidMount() {
		const productSlug = this.props.match.params.productSlug;
		const productId = getProductIdFromSlug(productSlug);
		this.fetchProduct(productId || productSlug); // Fallback to using slug as ID if not found
	}

	componentDidUpdate(prevProps) {
		const currentProductSlug = this.props.match.params.productSlug;
		const previousProductSlug = prevProps.match.params.productSlug;

		// Check if the productSlug has changed
		if (currentProductSlug !== previousProductSlug) {
			const productId = getProductIdFromSlug(currentProductSlug);
			this.fetchProduct(productId || currentProductSlug);
		}
	}

	fetchProduct(productId) {
		this.setState({ loading: true });
		try {
			fetchProductData(productId, (response) => {
				const product = response.data;
				// Register the product slug when we receive data
				if (product && product.id && product.title) {
					registerProductSlug(product.id, product.title);
				}
				
				this.setState({
					loading: false,
					product: product
				});
			});
		} catch (error) {
			this.setState({
				loading: false,
				errors: error.message
			});
		}
	}

	async addToFavorites(productId) {
		const { userEmail, userData, getUserData, history, isFavorite } = this.props;

		if (!userData) {
			history.push('/login');
			return;
		}

		if (isFavorite) {
			await deleteFromFavorites(productId, userEmail);
		} else {
			await addToFavorites(productId, userEmail);
		}

		getUserData();
	}

	render() {
		const productSlug = this.props.match.params.productSlug;
		const productId = getProductIdFromSlug(productSlug) || productSlug;
		const product = this.state.product;
		const { addToCart, isFavorite } = this.props;
		const { loading } = this.state;

		const pageTitle = product?.title
        ? `${product.title}`
        : `Loading...`;
		
		return (
			<Layout title={pageTitle}>
				<div className='product-page p-3 container-fluid container-min-max-width'>
					<ProductInfoWithSpinner
						isLoading={loading}
						productId={productId}
						product={product}
						addToCart={addToCart}
						addToFavorites={this.addToFavorites}
						isFavorite={isFavorite}
					/>
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const productSlug = ownProps.match.params.productSlug;
	const productId = getProductIdFromSlug(productSlug) || productSlug;
	
	return {
		userEmail: selectUserEmail(state),
		userData: selectUserData(state),
		isFavorite: selectIsFavorite(state, { ...ownProps, match: { params: { productId } } })
	};
};

function mapDispatchToProps(dispatch) {
	return {
		addToCart: (product) => dispatch(addToCart(product)),
		getUserData: () => {
			dispatch(getUserData());
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);