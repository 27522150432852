import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { connect } from "react-redux";

//components
import BackButton from "../../components/BackButton/BackButton";
import DropdownSort from "../../components/Dropdown/DropdownSort";
import FiltersSideBar from "../../components/FiltersSideBar/FiltersSideBar";
import WithSpinner from "../../components/HOCs/WithSpinner";
import ProductsList from "../../components/ProductsList/ProductsList";
import CartIsEmpty from "../../components/CartIsEmpty/CartIsEmpty";

//actions
import { getAllProductsIfNecessary } from "../../store/actions/products";
import { getAllCategoriesIfNecessary } from "../../store/actions/categories";

//selectors
import {
  selectSortedProducts, 
  selectURLSearchParams,
  selectProductsLoading,
  selectFilterOptions,
} from "../../store/selectors/products";
import { selectCategoryName } from "../../store/selectors/categories";

// Utils
import { getCategoryIdFromSlug } from "../../utils/slugs";
import { isEmptyArray } from "../../utils/misc";

// CSS
import "./Products.css";

// Modified for category slug usage
const createParamsFromProps = (props) => {
  const searchParams = new URLSearchParams();
  const { categorySlug } = props.match.params;
  
  // Get existing URL parameters for sorting, etc.
  const currentParams = new URLSearchParams(props.history.location.search);
  
  // Copy over non-category parameters
  for (let pair of currentParams.entries()) {
    if (pair[0] !== 'category') {
      searchParams.set(pair[0], pair[1]);
    }
  }
  
  // Add category if available from URL path
  if (categorySlug) {
    const categoryId = getCategoryIdFromSlug(categorySlug);
    if (categoryId) {
      searchParams.set('category', categoryId);
    }
  }
  
  return searchParams;
};

const ProductsListWithSpinner = WithSpinner(
  ({ history, params, categoryName, filterOptions, products }) => {
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);

    // Toggle sidebar on button click
    const toggleFilterSidebar = () => {
      setIsFilterSidebarOpen(!isFilterSidebarOpen);
    };
    
    useEffect(() => {
      if (isFilterSidebarOpen) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }

      // Optional cleanup to ensure class is removed if component unmounts
      return () => {
        document.body.classList.remove('menu-open');
      };
    }, [isFilterSidebarOpen]);

    return (
      <React.Fragment>
        <h2>{categoryName}</h2>
        <hr />
        <div className="d-flex products-container">
          <FiltersSideBar
            params={params}
            history={history}
            filterOptions={filterOptions}
            isActive={isFilterSidebarOpen}
          />
          {isFilterSidebarOpen && (<div className="sidePanelOverlay" onClick={() => { setIsFilterSidebarOpen(false) }}></div>)}

          <div className="container-fluid container-min-max-width px-0 px-md-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="filterBtn-mbl" onClick={toggleFilterSidebar}>
                <i className="fa-light fa-filter"></i> Filter
              </div>
              <div className="d-none d-md-inline-block">
                <BackButton goBack={history.goBack} />
              </div>
              {isEmptyArray(products) ? (
                <></>
              ) : (
                <DropdownSort params={params} history={history} />
              )}
            </div>
            {isEmptyArray(products) ? (
              <div className="w-100 d-flex align-items-center justify-content-center pt-5 mt-5">
                <CartIsEmpty />
              </div>
            ) : (
              <ProductsList products={products} />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
);

class Products extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getAllProductsIfNecessary();
    this.props.getAllCategoriesIfNecessary();
    
    // Process category from URL path parameter
    this.processUrlParams();
  }

  componentDidUpdate(prevProps) {
    // Handle URL path parameter changes
    if (prevProps.match.params.categorySlug !== this.props.match.params.categorySlug) {
      this.processUrlParams();
    }
  }

  // Process the URL parameters and update internal state
  processUrlParams() {
    const { match } = this.props;
    const { categorySlug } = match.params;
    
    if (categorySlug) {
      const categoryId = getCategoryIdFromSlug(categorySlug);
      if (categoryId) {
        // Update the search params used by selectors internally,
        // but don't change the visible URL (which now shows the slug)
        this._internalParams = new URLSearchParams();
        this._internalParams.set('category', categoryId);
      }
    } else {
      this._internalParams = new URLSearchParams(this.props.history.location.search);
    }
  }

  renderEmptyList() {
    return (
      <div className="w-100 vh-100 d-flex align-items-center justify-content-center flex-direction-column">
        <CartIsEmpty />
      </div>
    );
  }

  render() {
    const {
      history,
      visibleProducts,
      categoryName,
      productsLoading,
      filterOptions,
    } = this.props;

    // Use internal params to preserve category from URL path
    const params = this._internalParams || createParamsFromProps(this.props);

    return (
      <Layout title={categoryName || "Products"}>
        <div className="container-fluid container-min-max-width">
          <ProductsListWithSpinner
            isLoading={productsLoading}
            history={history}
            products={visibleProducts}
            categoryName={categoryName}
            params={params}
            filterOptions={filterOptions}
          />
        </div>
      </Layout>
    );
  }
}

// Custom selector that works with path parameters
const selectURLParamsFromProps = (state, ownProps) => {
  const params = new URLSearchParams(ownProps.history.location.search);
  const { categorySlug } = ownProps.match.params;
  
  if (categorySlug) {
    const categoryId = getCategoryIdFromSlug(categorySlug);
    if (categoryId) {
      params.set('category', categoryId);
    }
  }
  
  return params;
};

const mapStateToProps = (state, ownProps) => ({
  params: selectURLParamsFromProps(state, ownProps),
  visibleProducts: selectSortedProducts(state, ownProps),
  productsLoading: selectProductsLoading(state),
  filterOptions: selectFilterOptions(state),
  categoryName: selectCategoryName(state, ownProps),
});

const mapDispatchToProps = (dispatch) => ({
  getAllProductsIfNecessary: (state) => {
    dispatch(getAllProductsIfNecessary(state));
  },
  getAllCategoriesIfNecessary: (state) => {
    dispatch(getAllCategoriesIfNecessary(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);