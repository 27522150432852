import React, { useState, useEffect } from "react";
import { getDefaultImageUrl } from "../../utils/misc";
import { fetchMasters } from "../../apis/endpoints";

function ProductInfo({
  productId,
  product: {
    title,
    price,
    currency,
    itemNumber,
    brand,
    material,
    weight,
    description,
    images,
  },
  addToCart,
  addToFavorites,
  isFavorite,
}) {
  const defaultImage = getDefaultImageUrl(images);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(
    defaultImage?.color || null
  );
  const [quantity, setQuantity] = useState(1);
  const [productImage, setProductImage] = useState(defaultImage?.url || null);
  const [allSizes, setAllSizes] = useState([]);
  const [isAvailable, setIsAvailable] = useState(
    defaultImage?.isAvailable || false
  );
  const [availableSizes, setAvailableSizes] = useState(
    defaultImage?.sizes || []
  );

  useEffect(() => {
    getAllSizes();
  }, []);

  const getAllSizes = async () => {
    try {
      const { sizes } = await fetchMasters();
      setAllSizes(sizes || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleColorClick = (image) => {
    if (image) {
      setProductImage(image?.url);
      setSelectedColor(image?.color);
      setSelectedImage(image?.url);
      setAvailableSizes(image?.sizes);
      setIsAvailable(image?.isAvailable);
    }
  };

  const groupImagesByColor = (images) => {
    if (!Array.isArray(images)) return {};
    return images.reduce((acc, image) => {
      const color = image?.color || "unknown";
      if (!acc[color]) {
        acc[color] = [];
      }
      acc[color].push(image);
      return acc;
    }, {});
  };

  // In your component:
  const groupedImages = groupImagesByColor(images);

  return (
    <div className="product-info d-flex mb-5">
      <div className="image-wrapper me-5">
      <nav>
        <div
          className="nav nav-tabs flex-md-column mt-2"
          id="productDetailsNavThumb"
          role="tablist"
        >
          {groupedImages[selectedColor]?.map((image, idx) => (
            <button
              key={idx}
              className={`mb-2 nav-link ${selectedImage === image?.url ? "active" : ""}`}
              onClick={() => handleColorClick(image)}
            >
              <img src={image?.url} alt="nav-img" />
            </button>
          ))}
        </div>
      </nav>

        <div className="mainImage">
          <img src={productImage} alt="" />
        </div>
      </div>
      <div className="product-details">
        <h1>{title}</h1>
        <p className="h3 text-danger">
          {price} {currency}
        </p>
        <div className="product-desc">
          <p className="mb-0">{description}</p>
        </div>
        <div className="product-form">
          <div className="swatch swatch-1 option2 w-100">
            <div className="product-form__item">
              <label>Size</label>
              {allSizes?.map((size, index) => (
                <div
                  key={index}
                  className={`swatch-element ${size.toLowerCase()} ${
                    availableSizes.includes(size) ? "available" : "unavailable"
                  }`}
                >
                  <input
                    className="swatchInput"
                    id={`swatch-${size}-${index}`}
                    type="radio"
                    name="size"
                    value={size}
                    checked={selectedSize === size}
                    onChange={handleSizeChange}
                    disabled={!availableSizes.includes(size)}
                  />
                  <label
                    className="swatchLbl medium"
                    htmlFor={`swatch-${size}-${index}`}
                  >
                    {size}
                  </label>
                </div>
              ))}
            </div>

            <div className="product-form__item">
  <label>
    Color: <b>{selectedColor}</b>
  </label>
  <div className="swatch-options">
    {Object.keys(groupedImages)?.map((color, index) => (
      <div
        key={index}
        className="swatch-element color available"
        onClick={() => handleColorClick(groupedImages[color][0])}
      >
        <input
          className="swatchInput"
          id={`swatch-color-${index}`}
          type="radio"
          name="color"
          value={color}
          checked={selectedColor === color}
          readOnly
        />
        <label
          style={{ backgroundColor: color }}
          className={`swatchLbl color large bg-color-${color}`}
          htmlFor={`swatch-color-${index}`}
        ></label>
      </div>
    ))}
  </div>
</div>

          </div>
        </div>
        {isAvailable ? (
          <div className="product-action">
            <div className="product-form__item--submit">
              <button
                type="button"
                name="add"
                className="button-primary px-5"
                onClick={() => {
                  if (!selectedSize) {
                    alert("Please select size first.");
                    return null;
                  }
                  addToCart({
                    product: {
                      id: productId,
                      title: title,
                      price: price,
                      quantity: quantity,
                      currency: currency,
                      imageUrl: productImage,
                      color: selectedColor,
                      size: selectedSize,
                    },
                  });
                }}
              >
                <span>Add to cart</span>
              </button>
            </div>
          </div>
        ) : (
          <p style={{ color: "red" }}>Not Available Now.</p>
        )}

        <div className="infolinks d-flex flex-wrap align-items-center px-0">
          <a
            className="wishlist add-to-wishlist d-flex align-items-center"
            href="javascript:void(0);"
            style={{ color: isFavorite ? "#ff4343" : "" }}
            onClick={() => {
              addToFavorites(productId);
            }}
          >
            <i
              className={`fa-${
                isFavorite ? "solid" : "regular"
              } fa-heart me-1 `}
            ></i>{" "}
            <span>
              {isFavorite ? "Remove from favorites" : "Add to favorites"}
            </span>
          </a>
          <a
            className="wishlist shippingInfo d-flex align-items-center"
            href="javascript:void(0);"
          >
            <i className="fa-regular fa-paper-plane me-1"></i>{" "}
            <span>Delivery &amp; Returns</span>
          </a>
        </div>

        <p className="product-additionalinfo">
          Item Number:
          <span className="font-weight-bold">{itemNumber}</span>
        </p>
        <p className="product-additionalinfo">
          Brand:
          <span className="font-weight-bold">{brand}</span>
        </p>
        <p className="product-additionalinfo">
          Material:
          <span className="font-weight-bold">{material}</span>
        </p>
        <p className="product-additionalinfo">
          Weight:
          <span className="font-weight-bold">{weight}</span>
        </p>
      </div>
    </div>
  );
}

export default ProductInfo;
