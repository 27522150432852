import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getAllCategoriesIfNecessary } from "../../../store/actions/categories";
import { selectCategoriesData } from "../../../store/selectors/categories";
import "./Footer.css";
import { getCategorySlugFromId, registerCategorySlug } from "../../../utils/slugs";

function Footer({ categories }) {
  // State to toggle Help dropdown
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  // Toggle function for Help dropdown
  const toggleHelpDropdown = () => {
    setIsHelpOpen(!isHelpOpen);
  };

  return (
    <footer id="footer">
      <div className="site-footer">
        <div className="footer-top">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 footer-links pb-3">
                <ul className="links">
                  {categories.map((category) => (
                    <li key={category.id}>
                     <Link to={`/products/${getCategorySlugFromId(category.id) || registerCategorySlug(category.id, category.name)}`}>
                        {category.name}
                     </Link>
  
                    </li>
                  ))}
                  <li>
                    <Link to="/about" className="">
                      About
                    </Link>
                  </li>
                  <li>
                  <Link to="/terms-and-conditions">
                          Terms & Conditions
                        </Link>
                  </li>

                  {/* Help dropdown trigger */}
                  {/* <li className="help-dropdown">
                    <span
                      className="help"
                      onClick={toggleHelpDropdown}
                      style={{ cursor: "pointer" }}
                    >
                      Help{" "}
                      <i
                        style={{ fontSize: "12px" }}
                        class="fas fa-chevron-down"
                      ></i>
                    </span>

                   
                    <ul className="dropdown-menu">
                      <li>
                        <Link to="">FAQs</Link>
                      </li>
                      <li>
                        <Link to="">My Orders</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-conditions">
                          Terms And Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="">Shipping Policy</Link>
                      </li>
                      <li>
                        <Link to="">Returns & Exchange</Link>
                      </li>
                    </ul>
                    
                  </li> */}

                  {/*  */}
                </ul>
              </div>
              <div className="col-12 col-sm-12 d-flex flex-column align-items-center text-center   contact-box mt-2 ">
                <ul className="addressFooter d-flex flex-column">
                  <li className="email  text-center">
                    <p className="d-flex footer-links">
                      <i className="icon fa-regular fa-envelope me-2"></i>
                      <a href="mailto:kevin@nevermealwayswe.com">
                        kevin@nevermealwayswe.com
                      </a>
                    </p>
                  </li>
                  <li className="phone  d-flex justify-content-center text-center">
                    <p className="d-flex footer-links">
                      <i className="icon fa-regular fa-phone-volume me-2"></i>
                      <a href="tel:+14802318203">+1 (480) 231-8203</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12  d-flex justify-content-center align-items-center">
                <div className="footer-social w-100 text-center d-flex align-items-center justify-content-center">
                  <ul className="list--inline site-footer__social-icons social-icons">
                    <li>
                      <a
                        className="social-icons__link d-inline-block"
                        href="https://www.facebook.com/people/NMAW-Never-Me-Always-We/61560602731102/"
                        target="_blank"
                      >
                        <i className="icon fa-brands fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="social-icons__link d-inline-block"
                        href="https://www.facebook.com/people/NMAW-Never-Me-Always-We/61560602731102/"
                        target="_blank"
                      >
                        <i className="icon fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-end align-items-center">
                <ul className="payment-icons list--inline w-100 justify-content-end text-end">
                  {/* <li><i className="icon fa-brands fa-cc-stripe" aria-hidden="true"></i></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 copyright text-center  footer-links">
                © NMAW, 2024, Designed by{" "}
                <a href="https://apie.tech/" target="_blank">
                  Apie Tech
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const mapStateToProps = createStructuredSelector({
  categories: selectCategoriesData,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCategoriesIfNecessary: (state) => {
    dispatch(getAllCategoriesIfNecessary(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
// .product-page .image-wrapper .nav-tabs .nav-link img {
//   max-height: 100%;
// }