export function chunkArray(myArray, chunk_size) {
  // Check if myArray is actually an array
  if (!Array.isArray(myArray)) {
    return [];
  }
  
  let arrCpy = [...myArray]; // Using spread operator for a cleaner copy
  let results = [];
  
  while (arrCpy.length) {
    results.push(arrCpy.splice(0, chunk_size));
  }
  
  return results;
}
export const getDefaultImageUrl = (images) => {
  const defaultImage = images?.filter((image) => image?.default === true);
  return defaultImage?.length > 0 ? defaultImage[0] : null;
};

const moment = require("moment");
export const getDate = (value) => {
  if (!value) return "";
  const date = moment(value);
  const formattedDate = date.format("DD-MM-YYYY hh:mm:ss A");
  return formattedDate;
};
