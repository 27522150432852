import React from "react";
import { Link } from "react-router-dom";
import CustomSelect from "../CustomSelect/CustomSelect";
import CustomInput from "../CustomInput/CustomInput";
import { fetchMasters } from "../../apis/endpoints";
import MultiRangeSlider from "multi-range-slider-react";
import { debounce } from "lodash";
import { getCategorySlugFromId } from "../../utils/slugs";

class FiltersSideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      minPriceInput: 0,
      maxPriceInput: 0,
      maxPrice: 0,
      colors: [], // For managing multiple color selections
      sizes: [], // For managing multiple size selections
      types: [], // For managing multiple type selections
      selectedType: "",
      colorExpanded: true,
      sizeExpanded: true,
      typeExpanded: true,
      categoryExpanded: true, // Added for category expansion
    };
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
  }

  toggleSection(section) {
    this.setState((prevState) => ({
      [section]: !prevState[section],
    }));
  }

  async componentDidMount() {
    const { prices } = this.props.filterOptions;
    const maxPrice = Number(Math.max(...prices)) + 50;
    try {
      const res = await fetchMasters();
      const colorsList = res?.colors || [];
      const sizesList = res?.sizes || [];
      // Retrieve query params for types, color, and sizes
      const { params } = this.props;
      const colors = params.get("colors")?.split(",") || [];
      const sizes = params.get("sizes")?.split(",") || [];
      const types = params.get("types")?.split(",") || [];
      const selectedType = params.get("types") || "";

      this.setState({
        colorsList,
        sizesList,
        maxPriceInput: maxPrice,
        maxPrice: maxPrice,
        colors,
        sizes,
        types,
        selectedType,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  handleCategoryChange(categoryId) {
    const { history } = this.props;
    
    // Get the slug for this category
    const categorySlug = getCategorySlugFromId(categoryId);
    
    // If we have a slug, use it in the URL path
    if (categorySlug) {
      // Preserve other query parameters
      const currentParams = new URLSearchParams(history.location.search);
      currentParams.delete('category'); // Remove category from query params since it's in the path
      
      const queryString = currentParams.toString() 
        ? `?${currentParams.toString()}` 
        : '';
      
      // Navigate to the slug-based URL
      history.push(`/products/${categorySlug}${queryString}`);
    } else {
      // Fallback to old behavior if no slug is found
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set('category', categoryId);
      history.push({
        pathname: '/products',
        search: searchParams.toString()
      });
    }
  }

  handleOptionSelect(criteria, value) {
    // Special handling for category
    if (criteria === "category") {
      this.handleCategoryChange(value);
      return;
    }
    
    const { params, history } = this.props;
    const path = history.location.pathname;
    if (value === "") {
      params.delete(criteria);
    } else {
      params.get(criteria)
        ? params.set(criteria, value)
        : params.append(criteria, value);
    }
    history.push(`${path}?${params.toString()}`);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleCheckboxChange(criteria, value, isChecked) {
    const { params, history } = this.props;
    const path = history.location.pathname;

    let selectedItems = [...this.state[criteria]];

    if (isChecked) {
      selectedItems.push(value);
    } else {
      selectedItems = selectedItems.filter((item) => item !== value);
    }

    this.setState({ [criteria]: selectedItems });

    if (selectedItems.length > 0) {
      params.set(criteria, selectedItems.join(","));
    } else {
      params.delete(criteria);
    }

    history.push(`${path}?${params.toString()}`);
  }

  handleRadioChange(criteria, value) {
    const { params, history } = this.props;
    const path = history.location.pathname;

    this.setState({ selectedType: value });

    if (value) {
      params.set(criteria, value);
    } else {
      params.delete(criteria);
    }

    history.push(`${path}?${params.toString()}`);
  }

  handleSliderChange = debounce(({ minValue, maxValue }) => {
    if (
      minValue !== this.state.minPriceInput ||
      maxValue !== this.state.maxPriceInput
    ) {
      this.setState({
        minPriceInput: minValue,
        maxPriceInput: maxValue,
      });
      
      // Call handleOptionSelect for the new values
      this.handleOptionSelect("minPriceInput", minValue);
      this.handleOptionSelect("maxPriceInput", maxValue);
    }
  }, 300); 
  
  handleInputChange = (e) => {
    const { name, value } = e.target;
    const intValue = Number(value);

    if (name === "minPriceInput" && intValue <= this.state.maxPriceInput) {
      this.setState({ minPriceInput: intValue });
    } else if (
      name === "maxPriceInput" &&
      intValue >= this.state.minPriceInput
    ) {
      this.setState({ maxPriceInput: intValue });
    }

    this.handleOptionSelect(name, value);
  };

  handleClearFilters() {
    const { params, history } = this.props;
    const path = history.location.pathname;
    params.forEach((value, key) => {
      if (key !== "category") {
        params.delete(key);
      }
    });
    this.setState({
      minPriceInput: 0,
      maxPriceInput: this.state.maxPrice,
      colors: [],
      sizes: [],
      types: [],
    });
    history.push(`${path}?${params.toString()}`);
  }

  renderCategoryLinks() {
    const { categories } = this.props.filterOptions || {};
    if (!categories || categories.length === 0) return null;

    return (
      <div className="mb-3">
        <h4
          className="d-flex align-items-center"
          onClick={() => this.toggleSection("categoryExpanded")}
          style={{ cursor: "pointer" }}
        >
          Categories{" "}
          <i
            className={`fa-solid ms-auto ${
              this.state.categoryExpanded ? "fa-chevron-up" : "fa-chevron-down"
            }`}
          ></i>
        </h4>
        {this.state.categoryExpanded && (
          <div className="category-links">
            {categories.map(category => (
              <div key={category.id} className="category-item">
                <Link to={`/products/${getCategorySlugFromId(category.id)}`}>
                  {category.name}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { brands, materials } = this.props.filterOptions;
    const { isActive } = this.props;
    const {
      colorsList,
      colors,
      sizesList,
      sizes,
      selectedType,
      colorExpanded,
      sizeExpanded,
      typeExpanded,
    } = this.state;

    const { minPriceInput, maxPriceInput, maxPrice } = this.state;

    return (
      <div className={`filterSidebar ${isActive ? "filterSidebarIsOpen" : ""}`}>
        <h2>
          Filter{" "}
          <span onClick={() => this.handleClearFilters()}>
            Clear All Filter
          </span>
        </h2>

        {/* Category Links Section */}
        {this.renderCategoryLinks()}

        <div className="my-3">
          <h4 className="border-0 pb-0 mb-0">Price</h4>
          <MultiRangeSlider
            min={0}
            max={maxPrice}
            step={1}
            ruler={false}
            label={true}
            preventWheel={false}
            minValue={minPriceInput}
            maxValue={maxPriceInput}
            onInput={(e) => {
              this.handleSliderChange(e);
            }}
          />

          <div className="d-flex align-items-center price-inputs">
            <CustomInput
              type="number"
              id="minPriceInput"
              name="minPriceInput"
              value={minPriceInput}
              onChange={this.handleInputChange}
              min="0"
              max={maxPriceInput}
            />
            <span>to</span>
            <CustomInput
              type="number"
              id="maxPriceInput"
              name="maxPriceInput"
              value={maxPriceInput}
              onChange={this.handleInputChange}
              min={minPriceInput}
              max={maxPrice}
            />
          </div>
        </div>

        <CustomSelect
          className="form-select my-3"
          name="brand"
          defaultOption="Filter by brand"
          options={brands}
          handleOptionSelect={this.handleOptionSelect}
        />
        <CustomSelect
          className="form-select my-3"
          name="material"
          defaultOption="Filter by material"
          options={materials}
          handleOptionSelect={this.handleOptionSelect}
        />
        {/* Type Filter */}
        <div className="mb-3">
          <h4
            className="d-flex align-items-center"
            onClick={() => this.toggleSection("typeExpanded")}
            style={{ cursor: "pointer" }}
          >
            Type{" "}
            <i
              className={`fa-solid ms-auto ${
                typeExpanded ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </h4>
          {typeExpanded && (
            <>
              {["Male", "Female", "Unisex"].map((type) => (
                <div className="form-check" key={type}>
                  <input
                    type="radio"
                    className="form-check-input"
                    name="genderType" // All radio buttons must share the same name for single select
                    id={type}
                    checked={selectedType === type} // Use a single selected value
                    onChange={() => this.handleRadioChange("types", type)} // Update the handler to set the selected type
                  />
                  <label className="form-check-label" htmlFor={type}>
                    {type}
                  </label>
                </div>
              ))}
            </>
          )}
        </div>
        {/* Color Filter */}
        <div className="mb-3">
          <h4
            className="d-flex align-items-center"
            onClick={() => this.toggleSection("colorExpanded")}
            style={{ cursor: "pointer" }}
          >
            Color{" "}
            <i
              className={`fa-solid ms-auto ${
                colorExpanded ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </h4>
          {colorExpanded && (
            <div className="filter-categories">
              {colorsList?.map((color) => (
                <div className="form-check" key={color}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={color}
                    checked={colors.includes(color)}
                    onChange={(e) =>
                      this.handleCheckboxChange(
                        "colors",
                        color,
                        e.target.checked
                      )
                    }
                  />
                  <label className="form-check-label" htmlFor={color}>
                    {color}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Size Filter */}
        <div className="mb-3">
          <h4
            className="d-flex align-items-center"
            onClick={() => this.toggleSection("sizeExpanded")}
            style={{ cursor: "pointer" }}
          >
            Size{" "}
            <i
              className={`fa-solid ms-auto ${
                sizeExpanded ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </h4>
          {sizeExpanded && (
            <>
              {sizesList?.map((size) => (
                <div className="form-check" key={size}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={size}
                    checked={sizes.includes(size)}
                    onChange={(e) =>
                      this.handleCheckboxChange("sizes", size, e.target.checked)
                    }
                  />
                  <label className="form-check-label" htmlFor={size}>
                    {size}
                  </label>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default FiltersSideBar;