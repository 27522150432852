import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import { selectUserData } from "../../store/selectors/user";
import { getUserData } from "../../store/actions/user";
import axios from "../../apis/axios";
import { getDate } from "../../utils/helpers";

const Orders = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      dispatch(getUserData());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user && user.email) {
      fetchOrders();
    }
  }, [user]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("Authorization");
      const response = await axios.get("/user/orders", {
        headers: { Authorization: `${token}` },
      });
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout title="My Orders">
      <div className='cart-page container-fluid container-min-max-width d-flex flex-column mt-3'>
        {loading ? (
          <Loader />
        ) : orders.length === 0 ? (
          <p>No orders found.</p>
        ) : (
          <>
            <div className='w-100 cart mt-3'>
              <div className='row'>
                <div className='col-md-9 mx-auto'>
                  <div className='cart-header'>
                    <div className='cart-header-item orders cart-header-orderInfo'>Order ID</div>
                    <div className='cart-header-item orders cart-header-items'>Items</div>
                    <div className='cart-header-item orders cart-header-quantity text-start'>Total Amount</div>
                    <div className='cart-header-item orders text-center'>Status</div>
                    <div className='cart-header-item orders cart-header-total text-start'>Date</div>
                  </div>
                  <div className='cart-list'>
                    {orders.map((order) => (
                      <div className="cart-item px-0" key={order.id}>
                        <div className="cart-item-block cart-item-orderId">
                          {order.orderId}
                        </div>

                        <div className="cart-item-block cart-item-items">
                          <div className="cart-item__price-wrapper">
                              {order.products
                                .map((item) => `${item.color} ${item.size} (x${item.quantity})`)
                                .join(", ")}
                          </div>
                        </div>

                        <div className="cart-item-block cart-item-total text-start">
                          ${order.products.reduce((acc, item) => acc + item.price * item.quantity, 0)}
                        </div>
                        <div className="cart-item-block cart-item-quantity text-center">
                          <span className="cart-item-value">
                            <span className="money">
                              {order.stage}
                            </span>
                          </span>
                        </div>
                        <div className="cart-item-block cart-item-quantity text-start">
                          {getDate(order.createdAt)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );

};

export default Orders;



