import { createSelector } from 'reselect';
import { selectURLSearchParams } from './products';
import { getCategorySlugFromId, registerCategorySlug } from '../../utils/slugs';

const selectCategories = (state, ownProps) => state.categories;

export const selectCategoriesData = createSelector([ selectCategories ], (categories) => {
  // Ensure all categories have slugs registered
  categories.data.forEach(category => {
    if (category && category.id && category.name) {
      registerCategorySlug(category.id, category.name);
    }
  });
  return categories.data;
});

export const selectCategoriesLoading = createSelector([ selectCategories ], (categories) => categories.loading);

const selectCategoryId = createSelector([ selectURLSearchParams ], (params) => params.get('category'));

export const selectCategoryName = createSelector(
	[ selectCategoriesData, selectCategoryId ],
	(categories, categoryId) => {
		const category = categories.find((category) => category.id === categoryId);
		let categoryName;

		if (categoryId === null) {
			categoryName = 'All Products';
		} else if (categoryId === "favorites") {
			return "Favorites";
		} else {
			categoryName = category && category.name;
		}
		
		// Register the category slug if we have a valid category
		if (category && category.id && category.name) {
			registerCategorySlug(category.id, category.name);
		}
		
		return categoryName;
	}
);