// utils/slugs.js

/**
 * Generates a URL-friendly slug from a string
 * @param {string} text - Text to convert to slug
 * @returns {string} URL-friendly slug
 */
export const generateSlug = (text) => {
    if (!text) return '';
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  };
  
  /**
   * Store for mapping between IDs and slugs
   * This helps avoid duplicate database queries
   */
  const slugMaps = {
    categoryIds: {},  // slug -> id mapping
    productIds: {},   // slug -> id mapping
    categorySlugs: {}, // id -> slug mapping
    productSlugs: {}   // id -> slug mapping
  };
  
  /**
   * Register a category slug for lookup
   */
  export const registerCategorySlug = (id, name) => {
    const slug = generateSlug(name);
    slugMaps.categoryIds[slug] = id;
    slugMaps.categorySlugs[id] = slug;
    return slug;
  };
  
  /**
   * Register a product slug for lookup
   */
  export const registerProductSlug = (id, title) => {
    const slug = generateSlug(title);
    slugMaps.productIds[slug] = id;
    slugMaps.productSlugs[id] = slug;
    return slug;
  };
  
  /**
   * Get category ID from slug
   */
  export const getCategoryIdFromSlug = (slug) => {
    return slugMaps.categoryIds[slug];
  };
  
  /**
   * Get product ID from slug
   */
  export const getProductIdFromSlug = (slug) => {
    return slugMaps.productIds[slug];
  };
  
  /**
   * Get category slug from ID
   */
  export const getCategorySlugFromId = (id) => {
    return slugMaps.categorySlugs[id] || id;
  };
  
  /**
   * Get product slug from ID
   */
  export const getProductSlugFromId = (id) => {
    return slugMaps.productSlugs[id] || id;
  };